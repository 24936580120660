import { IMenuOption } from "@/services/DTOs/Misc";
import { PermissionCategory } from "@/services/DTOs/Permission";

export default [
  {
    text: "Recruitment card",
    to: "/opportunity/recruitment-card",
    icon: "mdi-account-details",
    permissions: [
      {
        name: "opportunity.recruitment-card",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Approval flow",
    to: "/opportunity/new-approve-experimental",
    icon: "mdi-check-circle-outline",
    permissions: [
      {
        name: "opportunity.new-approve-experimental",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Manage an existing job opening",
    to: "/opportunity/manage",
    icon: "mdi-briefcase-edit-outline",
    permissions: [
      {
        name: "opportunity.manage",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Update deadline",
    to: "/opportunity/deadline",
    icon: "mdi-calendar-clock",
    permissions: [
      {
        name: "opportunity.deadline",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Manager link",
    to: "/opportunity/manager-url",
    icon: "mdi-link",
    permissions: [
      {
        name: "opportunity.manager-url",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Tests",
    to: "/opportunity/tests",
    icon: "mdi-file-document-edit-outline",
    permissions: [
      {
        name: "opportunity.tests",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Reflection",
    to: "/opportunity/reflection",
    icon: "mdi-account-question",
    permissions: [
      {
        name: "opportunity.reflection",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Evaluation state",
    to: "/opportunity/evaluation-state",
    icon: "mdi-file-question",
    permissions: [
      {
        name: "opportunity.evaluation-state",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Data point",
    to: "/opportunity/data-point",
    icon: "mdi-folder-information-outline",
    permissions: [
      {
        name: "opportunity.data-point",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Reassign job opening operator",
    to: "/opportunity/reassign-opportunity-operator",
    icon: "mdi-account-switch",
    permissions: [
      {
        name: "opportunity.reassign-opportunity-operator",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "View history changes",
    to: "/opportunity/history-diff",
    icon: "mdi-history",
    permissions: [
      {
        name: "opportunity.history-diff",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Automated tasks",
    to: "/opportunity/automated-tasks",
    icon: "mdi-robot",
    permissions: [
      {
        name: "opportunity.tasks",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "RPO Approval Request",
    to: "/opportunity/new-approve-experimental?flow=rpoApprovalRequest",
    icon: "mdi-check-circle-outline",
    permissions: [
      {
        name: "opportunity.rpo-approval-request",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Distribution budget",
    to: "/opportunity/budget",
    icon: "mdi-cash-plus",
    permissions: [
      {
        name: "opportunity.budget",
        category: PermissionCategory.PAGE
      }
    ]
  },
  {
    text: "Social Media Campaign Titles Generation",
    to: "/opportunity/social-media-campaigns",
    icon: "mdi-subtitles",
    permissions: [
      {
        name: "opportunity.social-media-campaigns",
        category: PermissionCategory.PAGE
      }
    ]
  }
] as IMenuOption[];
